import React from "react"
import Seo from "../components/seo"
import { Link } from "gatsby"

export default function TyC() {
  return (
    <div id="home" className="pb-28">
      <Seo />

      <div className="prose mx-auto prose-2xl py-20">
        <p>
          <Link to="/consultoria">{"< "} Volver</Link>
        </p>
        <h1>Términos y Condiciones Concurso Consultoría Tecnológica</h1>
        <p>
          Los presentes términos y condiciones son aplicables a todos los
          concursos y sorteos que se organicen en la página web de SymLab
          (symlab.io), a menos que se indique expresamente lo contrario. Al
          participar en un Concurso, los participantes aceptan regirse por estas
          Reglas.
        </p>
        <p>
          Todos los concursos están organizados por Symmetrics Lab Tecnologías
          SpA. Symmetrics Lab Tecnologías SpA se reserva el derecho de cancelar
          o modificar el concurso, su organización o estas Reglas en cualquier
          momento sin previo aviso. Cualquier cambio se hará público en el
          concurso correspondiente o en estas Reglas.
        </p>
        <p>
          El fallo del concurso o sorteo será final y no se entablará
          correspondencia o discusión respecto a las Reglas, conducta,
          resultados y todos los demás asuntos relacionados con un concurso.
        </p>
        <p>
          Los empleados de Symmetrics Lab Tecnologías SpA, cualquier empresa
          involucrada en el Concurso o las empresas subsidiarias o asociadas a
          dicha persona, agentes o miembros de sus familias u hogares, no pueden
          participar en el Concurso. Symmetrics Lab Tecnologías SpA se reserva
          el derecho de verificar la elegibilidad de todos los participantes.
        </p>
        <p>
          Los participantes de los Concursos se comprometen a enviar información
          verdadera, actual y completa. También garantizan que tienen la
          capacidad legal para participar en el Concurso y que aceptan las
          presentes Reglas o, en su lugar, que tienen el consentimiento de sus
          padres para su participación en el Concurso y el uso de estas Reglas.
        </p>
        <p>
          Symmetrics Lab Tecnologías SpA se reserva el derecho de descalificar a
          cualquier participante si tiene motivos razonables para creer que el
          participante ha violado cualquiera de estos términos y condiciones.
        </p>
        <p>
          En el caso de que un participante sea descalificado, Symmetrics Lab
          Tecnologías SpA puede decidir si se debe seleccionar un participante
          de reemplazo. En este caso, cualquier participante adicional será
          seleccionado con los mismos criterios que el participante original y
          estará sujeto a estas Reglas.
        </p>
        <p>
          Solo se permite una participación por persona o empresa. Las
          inscripciones al concurso deben realizarse antes de la fecha de cierre
          especificada y conforme al procedimiento estipulado. De no hacerlo, se
          descalificará la entrada.
        </p>
        <p>
          No existe ningún requisito de compra para participar en un Concurso.
          Symmetrics Lab Tecnologías SpA no es responsable de ningún error,
          omisión, interrupción, eliminación, defecto, demora, robo,
          destrucción, alteración o acceso no autorizado a las participaciones
          de los concursos, siempre y cuando estos se produzcan como resultado
          de errores del servidor, virus, fallos informáticos u otras causas
          fuera de su control.
        </p>
        <p>
          Los participantes deben tener en cuenta que, a menos que se indique lo
          contrario, Symmetrics Lab Tecnologías SpA no devolverá las
          participaciones de los Concursos, incluidas aquellas que consisten en
          material artístico o de otro tipo.
        </p>
        <p>
          Los ganadores del premio serán elegidos por un jurado, a menos que se
          especifique lo contrario. Los desempates serán evaluados por
          Symmetrics Lab Tecnologías SpA y, si así lo exige la ley, por un
          árbitro independiente. En cualquier caso, la decisión de los jueces y
          Symmetrics Lab Tecnologías SpA será final y no se entablará ninguna
          correspondencia o discusión.
        </p>
        <p>
          Los ganadores del premio serán notificados de la manera y dentro del
          tiempo especificado en las condiciones de cada concurso. La devolución
          de cualquier notificación o la falta de respuesta dentro del tiempo
          indicado puede resultar en la descalificación y la selección de un
          ganador alternativo. No reclamar un premio dentro de este tiempo o de
          la manera especificada puede resultar en la descalificación y la
          selección de un ganador alternativo.
          <br />
          Si se otorga más de un premio, solo se otorgará un premio por
          participante. Los nombres de los ganadores del concurso se harán
          públicos en el sitio web de Symmetrics Lab Tecnologías SpA o a través
          de las redes sociales.
        </p>
        <p>
          Los premios no son transferibles. Symmetrics Lab Tecnologías SpA se
          reserva el derecho de sustituir premios de igual o mayor valor en
          cualquier momento.
        </p>
        <p>
          Los premios se otorgan a discreción de Symmetrics Lab Tecnologías SpA
          y no se otorgarán premios como resultado de acciones indebidas por
          parte de o en nombre de ningún participante. Todos los impuestos,
          seguros, transferencias, dinero para gastos y otros gastos (incluidas
          comidas o mejoras de gastos personales, etc.) según sea el caso, son
          responsabilidad exclusiva del ganador del premio, a menos que se
          indique lo contrario. Cualquier material enviado por los participantes
          de un concurso debe ser propiedad de la empresa o persona que
          participa y estar relacionada específicamente con el emisor. Por la
          presente, los participantes garantizan que la información que envíen y
          / o distribuyan no infringirá la propiedad intelectual, la privacidad
          o cualquier otro derecho de terceros, y no contendrá nada que sea
          calumnioso, difamatorio, obsceno, indecente, acosador o amenazante.
          Symmetrics Lab Tecnologías SpA se reserva el derecho, pero no la
          obligación, de examinar, filtrar y / o monitorear la información
          proporcionada y de editar, negarse a distribuir o eliminar la misma.
        </p>
        <p>
          Symmetrics Lab Tecnologías SpA no acepta ninguna responsabilidad por
          daño, pérdida, lesión o decepción sufrida por cualquier participante
          que ingrese a la competencia o como resultado de la aceptación de
          cualquier premio.
        </p>
        <p>
          Symmetrics Lab Tecnologías SpA no es responsable de ningún problema o
          mal funcionamiento técnico de cualquier red o líneas telefónicas,
          sistemas informáticos en línea, servidores o proveedores, equipos
          informáticos, fallas de software de cualquier correo electrónico o
          entrada que se reciba debido a problemas técnicos o congestión del
          tráfico en Internet, líneas telefónicas o en cualquier sitio web, o
          cualquier combinación de los mismos, incluyendo cualquier lesión o
          daño a la computadora o teléfono móvil del participante o de cualquier
          otra persona relacionada como resultado de la participación o descarga
          de cualquier material durante un concurso. Se exime de responsabilidad
          a Symmetrics Lab Tecnologías SpA por muerte o lesiones personales como
          resultado de una negligencia.
        </p>
        <p>
          Todos los datos personales relacionados con los participantes se
          utilizarán de acuerdo con la legislación vigente en materia de
          protección de datos en Chile y no se divulgarán a terceros sin el
          consentimiento previo de las personas y/o empresas.
        </p>
        <p>
          El Concurso y los términos y condiciones de este acuerdo se regirán
          por la ley chilena. Puede contactarnos en relación con cualquier
          concurso escribiendo a Symmetrics Lab Tecnologías SpA a través de
          nuestro formulario de contacto.
        </p>
        <h2>SOBRE EL PREMIO</h2>

        <p>El premio es una consultoría, que dura un mes:</p>
        <ul>
          <li>Semana 1: Envío de formulario con preguntas.</li>
          <li>Semana 2: Entrevista con el equipo.</li>
          <li>Semana 3: Estudio del caso – realización del informe.</li>
          <li> Semana 4: Presentación y entrega del informe</li>
        </ul>
        <p>El informe incluye:</p>
        <ol>
          <li>
            Evaluar y detectar los riesgos. Se trabajará en preguntas sobre la
            realidad tecnológica de o las startups. Puntos a evaluar del equipo
            tecnológico. Evaluación del equipo. · Aplicaciones y herramientas. ·
            Arquitectura e infraestructura. · Escalabilidad de la solución.{" "}
          </li>
          <li>Identificar sinergias y oportunidades.</li>
        </ol>
        <p>
          Estas sesiones se podrán realizar durante el 4Q del 2022 o el 1Q del
          2023, en una fecha acordada entre ambas partes.
        </p>
      </div>
    </div>
  )
}
